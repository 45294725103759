import {
  AvvNodeGrammar,
  DeltaParser,
  HtmlParser,
  isInsert,
  type MountedEditor,
  runDiagnostics,
  SelectionRange,
  type SuccessSelectionRange
} from '@avvoka/editor'
import {
  fromHtmlEntities,
  getObjectEntries,
  toHtmlEntities
} from '@avvoka/shared'
import type {SetNonNullable} from "type-fest";
import {type ComputedRef, onUnmounted, type Ref, ref, shallowRef} from "vue";
import {removeStylesFromDelta} from "~/features/editor/styles/hook";

// Returns true if editor contains text
export function hasText (editor: MountedEditor) {
  return editor.getDelta().ops.some((op) => isInsert(op) && op.insert && op.insert !== '\n')
}

export function appendFootnotes(editorHtml: string, editor: MountedEditor) {
  const bridge = EditorFactory.getEntry(editor.options.id!).get().bridge!
  editorHtml += '<avv-footnotes>'
  getObjectEntries(bridge.footnotes).forEach(([key, value]) => {
    editorHtml += `<avv-footnote data-id="${key}">${value}</avv-footnote>`
  })
  editorHtml += '</avv-footnotes>'
  return editorHtml
}

export function deleteFootnotes(editorHtml: string, editor: MountedEditor) {
  const bridge = EditorFactory.getEntry(editor.options.id!).get().bridge!
  // Extract footnotes and remove them from the editorHtml
  let footnotesMatch
  while (
    (footnotesMatch = /<avv-footnotes>.*?<\/avv-footnotes>/mg.exec(
      editorHtml
    )) !== null
  ) {
    editorHtml = editorHtml.replace(footnotesMatch[0], '')

    // Extract each footnote and set it to entry.bridge.footnotes
    const footnoteRegex =
      /<avv-footnote data-id="([^"]*?)">([\s\S]*?)<\/avv-footnote>/g
    let match
    while ((match = footnoteRegex.exec(footnotesMatch[0])) !== null) {
      if (match.index === footnoteRegex.lastIndex) {
        footnoteRegex.lastIndex++
      }

      const id = match[1]
      const value = match[2]
      bridge.footnotes[id] = value
    }
  }
  return editorHtml
}

export const unsanitisedHtmlNode = (html: string) => {
  const node = HtmlParser.parse(html)

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = fromHtmlEntities(child.data)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        child.attributes[key] = value
          .replace(/_Avvspace_aVV/g, ' ')
          .replace(/_Avvdot_aVV/g, '.')
      })
    }
  }
  return node
}

export const deltaToSanitisedHtml = (
  editor: MountedEditor,
  appendFootnotesAtEnd = true,
  runDiagnosticsAutoFix = true
) => {
  const styledNode = DeltaParser.parse(editor.scroll.getDelta());

  if (runDiagnosticsAutoFix) {
    const diagnostics = runDiagnostics(styledNode, editor.options.mode)
    for(const diagnostic of diagnostics) {
      console.warn('Found corrupted content: ', diagnostic.message, '. Running fix...')
      if((diagnostic.fixes?.length ?? 0) > 0) {
        // Apply first fix
        diagnostic.fixes?.[0]?.action?.()
      } else {
        console.error('No fixes found for this diagnostic')
      }
    }
  }

  const node = DeltaParser.parse(removeStylesFromDelta(styledNode.toDelta(), editor.getStyleStore().docxSettings.formats))

  const docxHtmlEntities = {
    60: '&lt;',
    62: '&gt;',
    34: '&quot;',
    38: '&amp;'
  }

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = toHtmlEntities(child.data, docxHtmlEntities, false)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        if (typeof child.attributes[key] === 'string') {
          child.attributes[key] = value
            .replace(/ /g, '_Avvspace_aVV')
            .replace(/\./g, '_Avvdot_aVV')
        }
      })
    }
  }

  AvvNodeGrammar.applyInlineNormalization(node, editor.options.mode)

  let html = node.toHTML(false, undefined, false)
  if (appendFootnotesAtEnd) {
    html = appendFootnotes(html, editor)
  }

  return html
}


type ValidSelectionRange = SetNonNullable<Required<SelectionRange>, keyof SelectionRange>;

/**
 * Creates a reactive reference to the current valid selection in the editor.
 *
 * This function sets up a subscription to the editor's selection changes and
 * updates a reactive reference when a valid user-initiated selection occurs.
 * It also handles cleanup of the subscription when the component is unmounted.
 *
 * @param editor The mounted editor instance to observe for selection changes.
 * @returns A reactive reference to the current valid selection range, or null if no valid selection.
 */
export const useEditorSelection = (editor: MountedEditor): Ref<ValidSelectionRange | null> => {
  const selection = shallowRef<ValidSelectionRange | null>(editor.selection.validValue);

  function event({newRange}: {newRange: SelectionRange}) {
    if (newRange.isValid && newRange.isUserEvent()) {
      selection.value = newRange;
    }
  }

  editor.selection.onChange.subscribe(event);

  onUnmounted(() => {
    editor.selection.onChange.remove(event);
  });

  return selection;
};
